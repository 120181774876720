
<template>
  <div id="user-edit-tab-info">    
    <h6 class="mb-3 text-uppercase bg-light p-2">
      <i class="mdi mdi-account-circle mr-1"></i> Other Info
    </h6>
    <div class="row gy-3 gx-3">
        <div class="col-12">
          <label class="form-label" for="businessName">Business name</label>
          <input id="businessName" type="text" class="form-control" v-model="data.business_name"
          :class="{ 'is-invalid': v$.data.business_name.$error }"  placeholder="Business name" name="business_name" />
          <div v-if="v$.data.business_name.$error"
            class="invalid-feedback">
            <span v-if="v$.data.business_name.required.$message">
              {{ v$.data.business_name.required.$message }}</span>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label" for="partnerType">Pertner type</label>
          <input id="partnerType" readonly type="text" class="form-control" v-model="data.partner_type"
           placeholder="Partner type" name="partner_type" />
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Country</label>
            <multiselect 
            mode="single"
            disabled
            readonly
            valueProp="id"
            trackBy="name" label="name"
            :options="countries" 
            v-model="data.country_id" placeholder="--select--"></multiselect>
          </div>
        </div>

        <div class="col-12">
          <label for="userbio">About</label>
          <textarea id="userbio"  v-model="data.about" class="form-control"
            rows="3" placeholder="Write something..." name="about"></textarea>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Multiselect from '@vueform/multiselect'
import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      data:{
        about: "",
        dob: null,
        gender: "",
        country_id: null,
        business_name: "",
        partner_type: "",
      },
    }
  },
  validations() {
    return {
      data:{
        paypal_email: {
          email: helpers.withMessage("Please enter valid email", email),
        },
        business_name:{
          required: helpers.withMessage("Your business name is required", required),
        },
      }
   }
  },
  computed: {
    countries(){
      return this.$store.state.countries;
    },
    activeUser(){
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    updateUser(){
      this.v$.$validate().then(result =>{
        if (!result) { this.alertError(this.v$.$errors[0].$message); return };
          const formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$store.dispatch("changeLoaderValue", true)
          this.$http.post("/account/update-info",formData)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit('UPDATE_USER_INFO', response.data.data)
            }
          })
      })
    },
    patchUser(){
      this.data = this.$filters.patchUpdatables(this.data, this.activeUser)
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
