
<template>
  <div id="user-edit-tab-info">
    <h6 class="mb-3 text-uppercase bg-light p-2">
      <i class="mdi mdi-account-circle mr-1"></i> Payout Info
    </h6>
    <div class="row gy-3 gx-3">
      <div class="col-sm-7">
        <label class="form-label" for="account_holder_name">Account holder name</label>
        <input id="account_holder_name" type="text" class="form-control" v-model="data.account_holder_name"
        :class="{ 'is-invalid': v$.data.account_holder_name.$error }"  placeholder="Account holder name" name="account_holder_name" />
        <div v-for="(item, index) in v$.data.account_holder_name.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
      <div class="col-sm-5">
        <label class="form-label" for="account_number">Account number</label>
        <input id="account_number" type="text" class="form-control" v-model="data.account_number"
        :class="{ 'is-invalid': v$.data.account_number.$error }"  placeholder="Account number" name="account_number" />
        <div v-for="(item, index) in v$.data.account_number.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
      <div class="col-md-4">
        <label class="form-label" for="bank_name">Bank name</label>
        <input id="bank_name" type="text" class="form-control" v-model="data.bank_name"
        :class="{ 'is-invalid': v$.data.bank_name.$error }"  placeholder="Bank name" name="bank_name" />
        <div v-for="(item, index) in v$.data.bank_name.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
      <div class="col-sm-6 col-md-4">
        <label class="form-label" for="routing_number">Routing number</label>
        <input id="routing_number" type="text" class="form-control" v-model="data.routing_number"
        :class="{ 'is-invalid': v$.data.routing_number.$error }"  placeholder="Routing number" name="routing_number" />
        <div v-for="(item, index) in v$.data.routing_number.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
      <div class="col-sm-6 col-md-4">
        <label class="form-label" for="account_holder_type">Account holder type</label>
        <select v-model="data.account_holder_type" name="account_holder_type" id="account_holder_type"
        class="form-select" :class="{ 'is-invalid': v$.data.account_holder_type.$error }">
          <option disabled value="" >--type--</option>
          <option value="individual">Individual</option>
          <option value="company">Company</option>
        </select>
        <div v-for="(item, index) in v$.data.account_holder_type.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>  
      </div>
    </div>
    <div class="row mt-3 justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      data:{
        paypal_email:"",
        account_holder_name: "",
        account_holder_type: "",
        bank_name: "",
        routing_number: "",
        account_number: ""
      },
    }
  },
  validations() {
    return {
      data:{
        paypal_email: {
          email: helpers.withMessage("Please enter valid email", email),
        },
        account_holder_name:{
          required: required,
        },
        account_holder_type: {
          required: required,
        },
        bank_name: {
          required: required,
        },
        routing_number: {
          required: required,
        },
        account_number: {
          required: required,
        },
      }
   }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    updateUser(){
      this.v$.$validate().then(result =>{
        if (!result) { this.alertError(this.v$.$errors[0].$message); return };
          const formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$store.dispatch("changeLoaderValue", true)
          this.$http.post("/account/payout-info",formData)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit('UPDATE_USER_INFO', response.data.data)
            }
          })
      })
    },
    patchUser(){
      this.data = this.$filters.patchUpdatables(this.data, this.activeUser.payout_info)
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
